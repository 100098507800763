/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'Ikea-preprod',
        clientId: 'vi-ikea',
        buttonText: 'Connexion Ikea',
        autoLogin: true,
      },
    ],
  ],
}
